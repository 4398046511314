.PlayLiveGamesdivHome_mobile {
    display: none;
}

.in-play-mobile {
    display: none;
}
.match-odd-desktop{
    text-decoration: none !important;
}
.match-odd-desktop a:hover{
    text-decoration: none !important;
}

.bet-slip-mobile {
    display: none;
}

.bet-slip-desktop {
    display: block;
}

@media (min-width: 0px) and (max-width: 992px) {
    .PlayLiveGamesdivHome>.hello {
        display: none;
    }

    .PlayLiveGamesdivHome {
        /* display: none; */
    }

    .bet-slip-mobile {
        display: block;
    }

    .bet-slip-desktop {
        display: none;
    }

    .headinghomeinplay {
        display: none;
    }

    .PlayLiveGamesdivHome_mobile {
        display: unset;
    }

    .PlayLiveGamesdivHome_mobile_container {
        background-image: url("../../src/assets/image_40.svg");
        background-size: cover;
        background-repeat: no-repeat;
        /* height: 535px !important; */
        width: 100%;
        /* object-fit: cover; */
        background-position: center;
    }

    .favouriteBg {
        background-image: url("../../src/assets/image_40.svg");
        background-size: cover;
        background-repeat: no-repeat;
        height: 100vh !important;
        width: 100%;
        /* object-fit: cover; */
        background-position: center;
    }

    .royalty_div {}

    .royalty_items {
        width: auto;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .offermainDiv {
        display: none;
    }

    .side-nav {
        padding: unset;
    }

    .side-nav-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90px;
        /* height: 88px; */
        border: none;
        border-radius: 16px;
        background: black;
        border-bottom: 1px solid #FFFFFF;
        border-radius: 0px;
    }

    .side-nav-items-active {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90px;
        /* height: 88px; */
        left: 0px;
        top: 0px;
        border: none;
        background: linear-gradient(103.36deg, #049C51 19.29%, #052D12 91.27%);
        border-radius: 0px;
    }

    .social-side-nav-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 16px;
        background: #49525A;
        /* border-bottom: 1px solid #FFFFFF; */
        border-radius: 0px;
    }

    .mobile_side_main_section {
        width: 441px;
        height: 293px;
        border-radius: 20px 20px 0px 20px;
        transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    .in-play-mobile {
        display: unset;
    }

    .in-play-mobile-left {
        background: linear-gradient(104.63deg, #049C51 12.92%, #052D12 90.58%), linear-gradient(100.65deg, #05E8B1 -5.23%, #067BE8 86.78%), #D9D9D9;
    }

    .in-play-mobile-right {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .in-play-mobile-left-inside {
        justify-content: center;
        align-items: center;
    }

    .in-play-mobile-left-inside {
        display: flex;
        text-align: center;
    }

    .in-play-second-row {
        display: flex;
        justify-content: center;
        /* align-items: center; */
    }

    .in-play-blue {

        background: #0DC5FF;
        border-radius: 2px;
    }

    .in-play-pink {
        background: #FFB5BD;
        border-radius: 2px;
    }

    .in-play-grey {
        background: #E8E8E8;
        border-radius: 2px;
    }

    .content {
        width: 117% !important;
        padding-right: 15px !important;
        margin-right: 10px;
    }

    .row-games {
        /* position: absolute; */
        align-items: center;
        width: 111%;
        padding: unset;
        transform: translateX(-7%);


    }
}

.arrow-icone-size{
    width: 35px;
}

.copy-icone-size{
    width: 28px;
    height: 28px;
}

.homapge-border-radius{
    /* border: 1px solid red !important; */
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}
.rounded-social-media{
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}