.shimmer {
  position: relative;
  background: linear-gradient(90deg, #c0c0c0 25%, #a0a0a0 50%, #c0c0c0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.shimmer-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: linear-gradient(
    90deg,
    transparent 25%,
    rgba(192, 192, 192, 0.4) 50%,
    transparent 75%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
