.midle-games-box-black {
    background-color: #111111;
}

.midle-games-box {
    width: 100%;
    /* height: 80px; */
    left: 0px;
    top: 0px;
    background: linear-gradient(136.11deg, #04502a 18.78%, rgba(0, 1, 0, 0) 132.82%);
}
.navbar-routing-text{
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
align-items: center;
color: #FFFFFF;

}
.navbar-routing-text-big{
font-style: normal;
font-weight: 600;
font-size: 24px;
align-items: center;
color: #FFFFFF;
}
.odd-match-class-name{
/* width: 100%; */
/* height: 60px; */
/* left: 0px;
top: 0px; */

background: linear-gradient(104.63deg, #049C51 12.92%, #052D12 90.58%), linear-gradient(100.65deg, #05E8B1 -5.23%, #067BE8 86.78%), #D9D9D9;
}

.norecord-found-css{
    background: linear-gradient(104.63deg, #049C51 12.92%, #052D12 90.58%), linear-gradient(100.65deg, #05E8B1 -5.23%, #067BE8 86.78%), #D9D9D9;
}

.odd-match-class-name-inside{
display: flex;
flex-direction: row;
padding: 0px;
gap: 47px;

}
.white-box-blue{
width: 50px;
height: 45px;
background: #0DC5FF;
border-radius: 2px;
}
.white-box-white{
width: 50px;
height: 45px;
background:#E8E8E8;
border-radius: 2px;
}
.white-box-blue-red{
    width: 50px;
    height: 45px;
    background: #FFB5BD;
    border-radius: 2px;
}
.shado-css{
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
}

.LiveTv > iframe {
    width: 100%;
}