@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100&family=Outfit:wght@300&display=swap");

* {
  font-family: "Sarabun";
}

/* Nav Bar */

.Navbar {
  background: #111111;
}

.text-nav {
  color: #999999 !important;
}

.nav-ul {
  list-style-type: none;
  margin-bottom: 0;
}

.nav-ul li {
  display: inline;
}

/* Footer */
.ul-footer {
  list-style-type: none;
}

.ul-footer li {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-transform: capitalize;
  color: #d0d0d0;
  padding-top: 6px;
}

.footer-heading {
  color: #ffffff;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
}

.bg-footer {
  background-image: url("./assets/images/Footer-bg.png");
  width: 100%;
}

.footerbg {
  background: #000000;
}

.footer-p {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.8;
}

.copyright {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-transform: capitalize;
  color: #929292;
}

.cardcasinogame {
  background-image: url("./assets/images/casinoimg1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  width: 100%;
  border-radius: 18px;
}

.cardcasinogame1 {
  background-image: url("./assets/images/casinoimg2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  width: 100%;
  border-radius: 18px;
}

.card-title-casino {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 35.0424px;
  line-height: 48px;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #ffffff;
}

.pcasino {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 17.5212px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #ffc766;
}

.headinghome {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: bold !important;
  font-size: 32px;
  line-height: 44px;
  display: flex;
  justify-content: center;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #111111;
}

.headinghomeinplay {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: bold !important;
  font-size: 32px;
  line-height: 44px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #ffffff;
}

.Offersforyoudiv {
  background: #000000;
}

.Offerscard1 {
  background-image: url("./assets/images/Offers 1 (1).png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  width: 100%;
  border-radius: 18px;
}

.Offerscard2 {
  background-image: url("./assets/images/Offers 1 (2).png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  width: 100%;
  border-radius: 18px;
}

.Offerscard3 {
  background-image: url("./assets/images/Offers 1 (3).png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  width: 100%;
  border-radius: 18px;
}

.astrologers1 {
  background-image: url("./assets/images/astrologers1 (2).png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  width: 100%;
  border-radius: 18px;
}

.astrologers2 {
  background-image: url("./assets/images/astrologers1 (2).png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  width: 100%;
  border-radius: 18px;
}

.PlayLiveGamesdiv {
  background-image: url("./assets/images/DashboardBackground1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.PlayLiveGamesdivHome {
  background: #fec348;
}

.selectedMenu {
  border-left: 4px solid green;
}

.selectedMenu-1 {
  border-left: 4px solid green;
  background-color: rgba(4, 140, 72, 0.08);
}

.footballcard {
  display: flex;
  padding: 0;
  /* margin-left: 0.5rem; */
  border: none;
  /* border-left: 15px solid #fec348; */
  outline: none;
  position: relative;
  /* scale: 0.8; */
}

.cardfootballbody {
  /* background: red; */
  position: absolute;
  top: -40%;
  left: 10%;
  transform: translate(-50%, -50%);
}

@media (min-width) and (max-width) {
  .cardfootballbody {
    top: unset !important;
  }

}

.popularcard {
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
}

.popularcardselected {
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
}

.Footballtext {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #111111;
}

.cardfootball {
  border-radius: 15px;
}

.footballcard-reverse {
  /* transform: skew(-10deg) !important; */
}

.footballcard:hover {
  transform: skew(0deg);
  /* width: 120%; */
  background: linear-gradient(90deg, #039e53 1.27%, #077227 100%);
  /* transform: skew(0deg); */
  /* border: 2.23529px solid #000000; */

  /* border-radius: 15px; */
  cursor: pointer;
  opacity: 5 s;
  padding: 0;
}


.homepageslidertext {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0;
  font-size: 5em;
  line-height: 98px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  background: linear-gradient(180deg, #dfff17 0%, #ffd5e4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.homepagesliderdiv {
  top: 18%;
}

.carousel-control-prev-icon {
  display: none;
}

.carousel-control-next-icon {
  display: none;
}

.btnslider {
  display: -webkit-box;
}

.inplaydiv {
  background: #111111;
}

.inplaymaindiv {
  color: black !important;
}

.headinginplaybox {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  /* identical to box height */

  text-transform: capitalize;

  color: #111111;
}

.bgcount {
  background: #0dc5ff;
  padding: 9px;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #1f1f1f;
}

/* AccountsPage css */
.flex-copy-section {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* border: 1px solid red; */
  gap: 20px;

}

.flex-copy-section img {
  margin-top: -18px;
}

.AccountsUser {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.input-group {
  width: 20%;
  background-color: #000000;
}

.image-user-dashboard {
  margin-top: 25px;
}

.account-dashboard-flex {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  text-align: center;
  /* gap: 1px; */
  margin-top: 30px;
  /* border: 1px solid red; */
}

.bonus-accounts {
  width: 25%;
  border-radius: 8px;
}

.dashboard-account {
  /* width: 50%; */
  background-color: #05914c;
  border-radius: 8px;
  /* margin-left: 1px; */
}

.my-dashboard {
  width: 25%;
  background-color: #272727;
  border-radius: 8px;
}

.dashboard-p {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
  margin-top: 13px;
  margin-left: 36px;
}

.dashboard-pl {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 33px;
  color: #ffffff;
  margin-top: 13px;
  margin-left: 36px;
}

.dashboard-p1 {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
  margin-left: 8px;
  padding-top: 13px;
}

.blue-tier {
  padding: 10px;
  width: 50%;
  margin: auto;
  background-color: #0d99ff;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  /* font-size: 14px; */
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 32px;
  border-radius: 8px;
}

.blue-tier1 {
  padding: 10px;
  margin-top: 140px;
  padding: 8px 16px;
  background-color: #0d99ff;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 32px;
  border-radius: 8px;
}

.name-author {
  padding-top: 12px;
}

.symbols-dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
}

.symbols-dashboard-back {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}

.symbols-dashboard-back .back-arrow {
  /* border-radius: 50%;
  display: flex;
  background: #424242;
  justify-content: center;
  align-items: center; */
  /* padding: 10px; */
  margin-left: 25px;
}

.account-infos-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  margin-top: 38px;
}

.name-user {
  margin-left: 20px;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.title-user {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: rgba(255, 255, 255, 0.58);
}

.bonus {
  margin-top: 50px;
  padding-bottom: 50px;
}

.vector-bonus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
  padding-bottom: 16px;
}

.vector-bonus:hover p {
  color: #0d99ff;
}

.vector-texts {
  font-family: "Sarabun";
  font-style: normal;
}

.vector-texts .welcome-bonus {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  justify-content: flex-start;
}

.blue-tier-action {
  padding: 10px;
  width: 90%;
  margin: auto;
  background-color: #0d99ff;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  /* font-size: 14px; */
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 30px;
  border-radius: 8px;
}

.blue-tier-action-payment {
  padding: 10px;
  width: 110%;
  margin: auto;
  background-color: #0d99ff;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  /* font-size: 14px; */
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 8px;
}

.other-banks select {
  width: 90%;
  margin: auto;
  padding: 10px;
  /* margin-top: -10px; */
  margin-bottom: 20px;
  border-radius: 10px;
  /* background-color: #272727; */
  color: #424242;
}

.banks-logos-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.banks-logos-left .banks-logos-left-basics {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-left: 20px; */
}

/* .banks-name-right {
  border: 1px solid blue;
} */
.games-bonus {
  border: 0.25px solid black;
  margin-top: 15px;
  background-color: #272727;
  border-radius: 8px;
}

.card-payment-options {
  display: flex;
  justify-content: space-between;
  padding: 40px 20px 40px 20px;
  background-color: #ffffff;
  width: 95%;
  margin: auto;
  /* border: 1px solid red; */
  color: #424242;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.card-payment-options .card-images-payment-balance {
  display: flex;
  justify-content: space-between;
  gap: 22px;
}

.contact-us-action:hover {
  color: #0dc5ff;
  text-decoration: underline;
}

.welcome-bonus-one {
  background-color: #272727;
  border-radius: 8px;
}

.welcome-bonus-two {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}

.images-games {
  margin-bottom: 20px;
}

.qr-code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
  /* border: 1px solid blue; */
  margin-top: 80px;
}

.list-group-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  margin: auto;
  text-decoration: none;
}

.list-group-item:hover {
  text-decoration: none;
}

.container-accordian {
  padding-top: 25px;
  padding-bottom: 25px;
}

.list-group-panel {
  background-color: #272727;
  /* border: 1px solid blue; */
}

.list-group-panel-one {
  /* background-color: #272727; */
  /* border: 1px solid blue; */
  color: black;
}

.list-group-item-list-group-item-success {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  padding-top: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  width: 100%;
  margin: auto;
  padding: 10px;
  margin-top: 15px;
  /* border-bottom: 0.5px solid black; */
  padding-bottom: 15px;
  color: rgba(243, 243, 243, 0.58);
  background: #000000;
}

.list-group-item-list-group-item-success-deposite {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  padding-top: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  width: 100%;
  margin: auto;
  padding: 10px;
  margin-top: 15px;
  /* border-bottom: 0.5px solid black; */
  padding-bottom: 15px;
  color: rgba(243, 243, 243, 0.58);
}

.list-group-item-list-group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  padding-top: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  width: 100%;
  margin: auto;
  padding: 10px;
  margin-top: 15px;
  padding-bottom: 15px;
  color: rgba(243, 243, 243, 0.58);
}

.list-group-item-list-group-item-success:hover {
  padding-top: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  /* color: white; */
}

.list-group-item-list-group-item-success-deposite:hover {
  padding-top: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
}

.form-controls {
  background: #1b1b1b;
  color: white;
  border: 0.25px solid black;
}

.input-groups {
  background: #1b1b1b;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  gap: 10px;
}

.deposite-method {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-top: 48px;
  padding-bottom: 24px;
  /* text-align: center; */
  color: #ffffff;
}

.deposit-payment-gateway {
  display: flex;
  justify-content: space-between;
  /* background: #3a3a3a; */
  width: 95%;
  margin: auto;
  /* border-bottom: 1px solid #c8c8c8; */
  /* border: 1px solid red; */
  background-color: #ffffff;
  color: #424242;
  /* border: 1px solid red; */
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  /* align-items: center; */
}

.deposit-payment-gateway .payment-options {
  width: 40%;
  /* border-right: 1px solid #c8c8c8; */
}

.banks-logos {
  padding-right: 10px;
}

.deposit-payment-gateway .options-show {
  width: 60%;
  /* border: 1px solid red; */
  background-color: #ebf6f0;
}

.deposit-payment-gateway .payment-options div {
  /* display: grid;
  grid-template-columns: repeat(1, 1fr); */
  padding: 15px;
}

.list-group-item-list-group-item:hover {
  padding-top: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  background: rgba(196, 196, 196, 0.08);
  color: #0d99ff;
  /* border: 1px solid red; */
}

.list-group-item {
  padding-top: 16px;
}

.dashboard-user-left {
  display: flex;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  text-decoration: none;
}

.dashboard-user-left i {
  text-decoration: none;
}

.dashboard-user-left-deposite {
  /* border: 1px solid red; */
  color: #111111;
}

/* .dashboard-user-left-deposite:hover {
  color: #0d99ff;
} */
.dashboardBoxInplay {
  background: linear-gradient(103.36deg, #049c51 19.29%, #052d12 91.27%);

  border-radius: 8px;
}

.classuser-dashboard-logout {
  display: flex;
}

.headinghomeOffers {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #ffffff;
}

.headinghomeastrologers {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #ffffff;
}

.offermainDiv {
  background: linear-gradient(180deg, #04934c 0%, #053416 47.78%);
}

.dropdown-menu {
  margin-left: -80px;
}

/* Cricket page */
.footballcard a {
  text-decoration: none;
}

.crickettitle {
  padding: 50px 0 97px 25px;
}

.odd-main-internal {
  padding: 50px 0 37px 70px;
}

.loginBg {
  background-image: url("./assets/loginBg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}

.cricketbg {
  background-image: url("./assets/images/cricketbg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 120px;
}

.casinoBg {
  /* background-image: url("./assets/CasinoBG-1.png"); */
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("./assets/CasinoBG-1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
}

.cricketdiv {
  max-width: 1500px;
  margin: auto;
  background: #111111;
}

.firsthead {
  padding-bottom: 37px;
}

.headingcricket {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
}

.paracricket {
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}

.paracricket2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}

.teamnames {
  display: flex;
  align-items: center;
}

.inplay {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 0;
}

.bang_flag {
  margin-right: 8px;
}

.eng_flag {
  margin-left: 8px;
}

.livetv {
  margin-right: 8px;
  width: 20px;
  height: 17px;
}

.bit {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #0d99ff;
}

.matchpoint {
  background: #1a1a1a;
  border-radius: 8px;
  padding: 20px;
}

.matchborder {
  border-left: 2px solid #0d99ff;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-left: 4px;
}

.textmatch {
  margin-left: 4px;
}

.matchback {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #0dc5ff;
}

.matchlay {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffb5bd;
}

.tooglebutton {
  height: 40px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.switch {
  margin: 4px;
}

.switchtext {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #111111;
  margin-bottom: 0;
}

.pointdetails {
  background: #ffffff;
  border-radius: 0px 8px 8px 0px;
  border-left: 4px solid #009e26;
  height: 56px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  width: 884px;
}

.pointdetailsname {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  color: #111111;
  margin-left: 26px;
  margin-bottom: 0;
}

.pointback {
  display: flex;
  gap: 3%;
}

.pointdetailsnumber {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #1f1f1f;
  margin-bottom: 0;
  background: #0dc5ff;
  border-radius: 2px;
  padding: 12px 12px;
}

.pointdetailsnumberlay {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #1f1f1f;
  margin-bottom: 0;
  background: #ffb5bd;
  border-radius: 2px;
  padding: 12px 12px;
}

.pointdetailsnumberdra {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #1f1f1f;
  margin-bottom: 0;
  background: #aaaaaa;
  border-radius: 2px;
  padding: 12px 12px;
}

.third {
  margin-top: 20px;
}

.pointempty {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  background: #e8e8e8;
  border-radius: 2px;
  padding: 12px 13px;
  margin-bottom: 0;
}

.four {
  margin-top: 20px;
}

.pointaveg {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: #111111;
  margin-bottom: 0;
}

/* odd-main page */
.oddbg {
  background-image: url("./assets/images/oddbg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 120px;
}

.odd-main {
  margin: auto;
  max-width: 1524px;
  padding-bottom: 32px;
}

.oddmain-head {
  border-left: 2px solid #0d99ff;
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
  color: #ffffff;
  margin-left: 4px;
}

.odd-main-search {
  box-sizing: border-box;
  position: relative;
}

.search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
}

.search-input {
  box-sizing: border-box;
  width: 305px;
  height: 40px;
  padding-right: 8px;
  font-size: 13px;
  background: #1b1b1b;
  border-radius: 8px;
  border: 1px solid rgb(36, 36, 36);
  color: #999999;
  padding-left: 42px;
  font-weight: 400;
  font-size: 16px;
}

.oddmianborder {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #999999;
  text-align: center;
}

.oddmianborder:hover {
  border-bottom: 2px solid #009e26;
  color: #009e26;
}

.odd-toogle {
  height: 40px;
  width: 1020px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.oddmain-title {
  border-right: 2px solid #111111;
  margin-bottom: 0;
}

.oddmain-border {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #111111;
}

.odd-pointdetails {
  background: #ffffff;
  border-radius: 0px 8px 8px 0px;
  border-left: 4px solid #009e26;
  height: 56px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  width: 1020px;
}

.odd-pointnumber {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #1f1f1f;
  margin-bottom: 0;
  background: #0dc5ff;
  border-radius: 2px;
  padding: 6px 4px;
}

.odd-pointempty {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  background: #e8e8e8;
  border-radius: 2px;
  padding: 6px 4px;
  margin-bottom: 0;
}

.odd-pointnumberlay {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #1f1f1f;
  margin-bottom: 0;
  background: #ffb5bd;
  border-radius: 2px;
  padding: 6px 4px;
}

.odd-pointback {
  border-right: 2px solid #111111;
}

.odd-table-points {
  width: 40.88888888%;
}

.odd-boost {
  display: flex;
  gap: 8px;
  top: -8px;
}

.odd-toss {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-transform: capitalize;
  color: #111111;
  position: absolute;
  right: 450px;
  margin-top: 50px;
}

.odd-morediv {
  padding: 0;
  margin-left: 38px;
}

.odd-more {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #009e26;
  margin-bottom: 0;
}

.odd-teamntrackname {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 0;
  text-align: center;
}

.odd-space {
  width: 70px;
}

.odd-teamtrack {
  padding: 17px 0;
}

.odd-main2 {
  margin: auto;
  max-width: 1524px;
  padding-bottom: 32px;
  padding-top: 32px;
}

.odd-matchpoint {
  padding: 20px;
}

.odd-toogle-space {
  width: 115px;
}

.odd-toogle-space2 {
  width: 38.6666666%;
}

.slick-dots {
  display: none !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: "Sarabun";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: black !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* navbar hover sports */
.child-dropdown {
  display: none;
  position: relative;

  /* width: 100%; */
}

.child-dropdown-casino {
  display: none;
  position: relative;
}

.child-dropdown-promotions {
  display: none;
  position: relative;
}

.dropdown:hover .child-dropdown {
  border-radius: 20px;
  display: block;
  position: absolute;
  z-index: 100;
  /* border: 1px solid white; */
  /* margin-left: -50px;
  top: 93px; */
}

.dropdown:hover .child-dropdown-casino {
  border-radius: 8px;
  display: block;
  position: absolute;
  z-index: 100;
  /* border: 1px solid red; */
  margin-left: -200px;
  /* top: 61px; */
}

.dropdown:hover .child-dropdown-promotions {
  border-radius: 8px;
  display: block;
  position: absolute;
  z-index: 100;
  /* border: 1px solid red; */
  margin-left: -400px;
  /* top: 93px; */
}

.nav-heading-main {
  width: 200%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  cursor: pointer;
  padding-top: 25px;
  padding-bottom: 50px;
}

.nav-heading-main:hover {
  color: white;
  /* border: 1px solid red; */


}

.under-image {
  padding-top: 20px;
  text-align: center;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  text-decoration: none;
  color: #E8E8E8;
  border: 1px solid #000000;
}

.under-image:hover {
  text-decoration: none;
  color: #067BE8;
}

.imagediv2 {
  /* border: 1px solid red; */
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  list-style: none;
  gap: 40px;
  background-color: black;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  font: 14px Roboto, sans-serif;
  padding: 40px;
}

.imagediv2>li>img {
  /* border: 1px solid red; */
  width: 300px;
  /* height: 200px; */
}

.info>p {
  /* margin-top: 20px; */
  /* font-family: "Sarabun"; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;

  color: #ffffff;
  /* border: 1px solid red; */
}

.infop {
  font-weight: bold;
}

.info p:hover {
  color: #0d99ff;
}

.borderinplay {
  border-radius: 10px;
}

.matchpointHome {
  border-radius: 8px;
}

.pointdetailsHome {
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  border-left: 4px solid #009e26;
  /* height: 56px; */
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.pointleft2 {
  margin-left: 9%;
}

.pointleft {
  margin-left: 4%;
}

.header-mobile {
  display: none;
}

.mobile-footer {
  display: none;
}

/* Mobile responsive */

@media only screen and (max-width: 600px) {
  .footballcard {
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
  }



  .popularcard {
    flex-direction: column;
    align-items: center;
  }

  .popularcardselected {
    flex-direction: column;
    align-items: center;
    background-color: #009e26;
  }

  .footballcard>img {
    width: 40px;
  }

  .Footballtext {
    font-size: 20px;
    word-break: normal;
    text-align: center;
  }

  .inplaydiv {
    display: none;
  }

  .header-desktop {
    display: none;
  }

  .header-mobile {
    display: block;
  }

  .header-button {
    background: #2a2a2a !important;
    border-radius: 8px;
    border-color: #2a2a2a !important;
    margin-left: 30px;
  }

  .mobile-header-name {
    font-size: 11px;
    font-weight: 700;
    line-height: 15px;
    margin-bottom: 0;
    color: #ffffff;
    font-family: "Sarabun";
  }

  .mobile-bal {
    font-weight: 400;
    font-family: "Sarabun";
    font-size: 10px;
    line-height: 14px;
    color: #fec348;
    margin-bottom: 0;
  }

  .mobile-header-profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .heder-dropdown {
    background-color: #111111 !important;
    border-radius: 35px;
    color: #ffffff !important;
  }

  .AccountsUser {
    flex-direction: column;
    display: none;
  }

  .my-dashboard {
    width: unset;
  }

  .account-dashboard-flex {
    flex-direction: column;
  }

  .dashboard-account {
    width: unset;
  }

  .qr-code {
    flex-direction: column;
  }

  .bonus-accounts {
    width: unset;
  }

  .mobile-footer {
    display: block;
  }

  .mobile-sign {
    margin: 8px;
  }

  .mobile-payment {
    display: none;
  }

  .deposit-payment-gateway {
    flex-direction: column;
  }

  .deposit-payment-gateway .payment-options {
    width: unset;
  }

  .deposit-payment-gateway .options-show {
    width: unset;
  }

  .mobile-upload {
    flex-direction: column;
  }

  .mobile-screenshot {
    width: unset !important;
    max-width: unset !important;
  }

  .owl-dots {
    position: absolute;
    /* background: red; */
    z-index: 100;
    margin-left: -10%;
  }

  .owl-carousel span {
    width: 10px !important;
    height: 10px !important;
    margin: 5px 7px;
    background: grey !important;
    display: block;
    -webkit-backface-visibility: visible;
    -webkit-transition: opacity 200ms ease;
    -moz-transition: opacity 200ms ease;
    -ms-transition: opacity 200ms ease;
    -o-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    /* border: 1px solid red; */

  }

  .owl-carousel .active span {
    width: 10px !important;
    height: 10px !important;
    margin: 5px 7px;
    background: #fff !important;
    display: block;
    -webkit-backface-visibility: visible;
    -webkit-transition: opacity 200ms ease;
    -moz-transition: opacity 200ms ease;
    -ms-transition: opacity 200ms ease;
    -o-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    /*  */
  }



  .flex-copy-section {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    /* border: 1px solid red; */
    gap: 20px;

  }

  .flex-copy-section img {
    margin-top: -18px;
  }

  .cardfootballbody {
    left: -27%;
    top: unset;
  }



}


.mobileBottomDiv {
  background: #000000;
  position: fixed;
  bottom: 0;
  width: 97%;
  display: flex;
  justify-content: space-around;
  padding: 12px 10px;
}

.footer-text {

  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #048c48;
  margin-bottom: 0;
}

.footer-text2 {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 0;
}

input[type="radio"] {
  accent-color: #000000;
}

.input-radio-checked {
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  align-items: center;
  gap: 10px;
  /* border: 1px solid red; */
}

.images-dashboard-user-profile img {
  /* border: 1px solid red; */
  padding-right: 8px;
}

.images-dashboard-promotion {
  border: 1px solid red;
  padding-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  gap: 25px;
  color: black;
  background-color: #FFA500;
  ;
}

/* fast-upi page css */
.fast-upi-page {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: auto;
}

.fast-upi {
  /* border: 1px solid blue; */
  width: 100%;
}

.link {
  list-style: none;
}

.date-time {
  margin-bottom: 0;
}

.team-points {
  width: 6%;
}

.team-points2 {
  width: 13%;
}

.footer-link {
  text-decoration: none;
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction: column;
}

.dropdown-items-navabar {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

/* my-bets-css-section */
.my-bat-dates-section {
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
  gap: 21px;
  padding-top: 51px;
}

.my-bat-dates-section .dates-my-bets {
  border: 1px solid #05914c;
  background-color: #05914c;
  color: white;
  /* padding-bottom: 5px; */
  border-bottom: 1px solid white;
}

.bats-past-current {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
  margin-top: 56.5px;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
}

.bats-past-current-1 {
  display: flex;
  justify-content: flex-start;
  /* gap: 150px; */
  gap: 50px;
  align-items: center;
  width: 90%;
  margin: auto;
  margin-top: 56.5px;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  /* border: 1px solid red; */
}

.bats-past-current select {
  /* border: 1px solid blue; */
  background-color: #05914c;
  color: white;
  padding: 10px;
  border: 1px solid rgb(0, 200, 255);
  border-radius: 4px;
}

.bets-description-data {
  /* border: 1px solid red; */
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  background-color: #ffffff;
  border-radius: 4px;
  margin-top: 28px;

  color: #424242;
}

.bets-description-data .my-bets-desc {
  width: 20%;
  border: 1px solid white;
  padding: 20px;
  border-radius: 4px;
}

.bets-desc-tabs {
  width: 80%;
  /* border: 1px solid white; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  /* border: 1px solid red; */
}

.current-no-bets {
  width: 90%;
  margin: auto;
  margin-top: 8px;
  background-color: #ffffff;
  color: #424242;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  border-radius: 4px;
  /* identical to box height */
  text-transform: capitalize;
  color: rgba(66, 66, 66, 0.8);
  padding: 25px;
}

.border-bottom-blue {
  border-bottom: 3px solid #0d99ff;
}

/* withdraw page -css */
.withdraw-banks-div-flex {
  width: 95%;
  margin: auto;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  gap: 10px;
}

.withdraw-banks-div-flex .withdraw-under-inner-div {
  /* border: 1px solid white; */
  background-color: #ebf6f0;
  border-radius: 4px;
  color: #235160;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-transform: capitalize;
  width: 35%;
  /* height: 200px */
}

.withdraw-under-inner-div .image-withdraw-sides {
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-start;
  padding: 12px;
}

.withdraw-under-inner-div .withdraw-p-tag {
  /* border: 1px solid red; */
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
}

.withdraw-class-p {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  color: #009e26;
  /* margin-top: 50px; */
}

.withdraw-class-p1 {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  color: #009e26;
  margin-top: 40px;
  /* border: 1px solid red; */

}

.withdraw-class-p3 {
  width: 90%;
  margin: auto;
  /* display: flex; */
  justify-content: flex-end;
  align-items: baseline;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  color: #009e26;
  margin-top: 20px;
  /* border: 1px solid blue; */

}

.withdraw-class-p2 {
  width: 90%;
  margin: auto;
  /* display: flex; */
  /* bottom: 0; */
  /* justify-content: flex-end; */
  /* align-items: baseline; */
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  color: #009e26;
  /* margin-top: 20px; */
  /* border: 1px solid blue; */

}

.withdrawal-option-cjoode {
  /* border: 1px solid red; */
  margin-top: 62px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height */
  padding-left: 20px;
  color: #ffffff;
}

.flex-withdaw-quick {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.inputs-banks-holders-div {
  margin-top: 28px;
}

.bansk-holder-one {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  background-color: #ffffff;
  border-radius: 4px;
  margin-top: 28px;
  margin-left: 15px;
}

.bansk-holder-one .inputs-banks-holders-div {
  /* border: 1px solid red; */
  background-color: #ffffff;
  border-radius: 4px;
  margin-left: 15px;
}

.inputs-banks-holders-div input {
  background-color: #ffffff;
  border-radius: 4px;
  width: 100%;
  padding: 8px;
  border: 0.5px solid black;
}

.inputs-banks-holders-div select {
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  width: 100%;
  padding: 8px;
  margin-top: 15px;
  border: 0.5px solid black;
}

.bansk-holder-two {
  width: 25%;
}

.actions-buttons-submit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
  /* padding: 20px; */
}

.actions-buttons-submit .btns-2 {
  width: 45%;
  border-radius: 8px;
  margin-top: 15px;
  padding: 5px;
  margin-bottom: 15px;
  padding: 8px 16px;
  gap: 10px;
  background: #0d99ff;
  border-radius: 8px;
  color: white;
  border: none;
}

.actions-buttons-submit .btns-1 {
  width: 45%;
  border-radius: 8px;
  margin-top: 15px;
  padding: 5px;
  margin-bottom: 15px;
  padding: 8px 16px;
  gap: 10px;
  border-radius: 8px;
  background-color: white;
  color: #0d99ff;
  border: 1px solid #0d99ff;
}

.two-banks-div {


  width: 95%;
  margin: auto;
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  gap: 5px;
}

@media screen and (max-width: 676px) {
  .inputs-banks-holders-div {
    width: 100%;
    /* border: 1px solid red; */
    margin-left: 0 !important;
    margin-top: 0 !important;

  }

  .bet-karo-marquee-heading {
    /* border: 1px solid red; */
    display: inline-block;
    padding: 0px 1em 0px 2em;
    height: 30px;
    line-height: 2.2;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgb(0, 0, 0);
  }

  .login-signup-content {
    font-size: 12px;
    padding: 6px 2px;
    /* background: transparent;
    box-shadow: none; */
    font-size: 12px;
    text-transform: uppercase;
    color: rgb(230, 230, 230);
    border: none;
    /* color: red;
    border: 1px solid red; */
  }

  .games-headind-boxeds {
    /* border: 1px solid red; */
    margin-left: -5px;
  }

  .social-media-handles {

    /* border: 1px solid red; */
    display: flex;
    justify-content: flex-end;
    padding-top: 19%;
    padding-left: 15.5%;

  }

  .social-media-handles .insta-twitter-logos {
    /* border: 1px solid  red; */
    padding-top: 15px;
    border-radius: 10px;


  }

  .insta-twitter-logos div {
    background-color: rgb(255, 255, 255);

  }

  .actions-buttons-div {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
  }


  .inputs-banks-holders-div input {
    width: 80%;
    margin-top: 15px;
    border-color: grey;
  }

  .inputs-banks-holders-div input::placeholder {
    font-size: 12px;
  }

  .inputs-banks-holders-div select {
    width: 80%;
    border-color: grey;
    font-size: 12px;
    color: grey;
  }

  .actions-buttons-submit {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }

  .actions-buttons-submit .btns-2 {
    width: 80%;
    margin: auto;
    margin-top: 20px;
  }

  .actions-buttons-submit .btns-1 {
    width: 80%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 40px;
  }

  .flex-withdaw-quick {
    /* border: 1px solid red; */
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }

  .bansk-holder-two {
    width: 50%;
    margin: auto;
    margin-bottom: 20px;
  }

  .bats-past-current-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
  }

  .withdraw-banks-div-flex {
    /* border: 1px solid red; */
    width: 100%;
    margin: auto;
    padding: 25px 20px;
  }

  .withdraw-mobile {
    margin-top: 19px;
  }

  .withdraw-under-inner-div {
    width: 100%;
    /* border: 1px solid blue; */
  }

  .image-withdraw-sides img {
    display: none;
  }

  .withdraw-p-tag p {
    /* border: 1px solid red; */
    font-size: 9px;
    /* margin-bottom: 0; */
  }

  .my-bat-dates-section {
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .bets-desc-tabs {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    width: 100%;
    gap: 5px;
  }

  .bats-past-current {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .bats-past-current select {
    margin-top: 20px;
  }

  .my-bets-desc {
    display: none;
  }

  .topGames {
    font-size: 1.25rem !important;
  }

  .pass-mobile-bg {
    border-radius: 4px;
    padding: 20px;
  }

  .pass-mobile-bg input {
    width: 100%;
    border-color: grey;
    border-radius: 10px;
    padding: 14px;
  }

  .pass-mobile-bg input::placeholder {
    font-size: 12px;
  }

  .pass-mobile-bg select {
    width: 100%;
    border-color: grey;
    border-radius: 10px;
    padding: 14px;
  }

  .actions-buttons-submit .pass-mobile-submit {
    width: 100% !important;
  }

  .add-bank-bg {
    background: #0D99FF;
    border-radius: 8px;
    width: 150px;
    padding: 20px;
    margin: auto;
    margin-bottom: 50px;
  }

  .bank-btn-name {
    color: #111111;
    font-size: 12px;
    font-weight: 400;
    margin-top: 15px;
  }

  .bank-btn {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .inp-radio {
    width: unset !important;
  }

  .bank-details {
    background: #FFFFFF;
    border-radius: 4px;
    margin-top: 12px;
    padding: 8px;

  }

  .bank-details-div {
    background: #EBF6F0;
    padding: 0 10px 10px 10px;
    border-radius: 4px;
  }

  .bank-btn-submit {
    margin-bottom: 15px;
    /* margin-left: -10px; */
    padding: 10px 16px;
    background: #0d99ff;
    /* border-radius: 4px; */
    color: white;
    border: none;
    font-weight: 600;
    font-size: 9.42308px;
  }

  .bank-details-blue {
    width: 100px;
  }

  .bank-acc {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bank-edit {
    color: #0D99FF;
    font-weight: 400;
    font-size: 8.07692px;
  }

  .bank-edit-div {
    display: flex;
    gap: 17%;
  }

  .bank-detail-entry {
    text-align: initial !important;
  }

  .entry-name {
    color: #009E26;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
  }

  .entry-name2 {
    color: #009E26;
    font-weight: 600;
    font-size: 10px;
    margin-bottom: 4px;
  }

  .remark {
    background: #FFFFFF;
    border-radius: 1.34615px;
  }

  .entry-remark {
    font-weight: 400;
    font-size: 10px;
    color: #111111;
    margin-bottom: 0;
    text-align: initial !important;
    padding: 5px;
  }

  .bank-header {
    justify-content: unset;
  }

  .account-infos-details {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* border: 1px solid red; */
    justify-content: flex-start;
    border-bottom: 1px solid white;
    /* align-items: center; */
    /* padding-left: 20px;
    margin-top: 25px; */
  }

  .account-infos-details .name-user {
    display: flex;
    /* border: 1px solid red; */
    justify-content: flex-start;


  }

  .account-infos-details .title-user {
    display: flex;
    /* border: 1px solid red; */
    justify-content: flex-start;
    margin-left: 20px;
    font-size: 18px;

  }

  .history-mobile {
    padding-top: 25px;
  }


}

.content {
  width: 117% !important;
  padding-right: 15px !important;
  margin-right: 10px;
}

.row-games {
  /* position: absolute; */
  align-items: center;
  width: 108%;
  padding: unset;
  transform: translateX(-7%);
  padding-bottom: 2rem;

}

.cardfootball {

  /* transform: skew(20deg);
       */
  overflow: hidden;
  height: 50px;
}

.text-small-asd {
  font-size: 6px;
}

.br0 {
  border-radius: 0 !important;
}

.topGames {
  font-size: 32px;
}

.div-live {
  padding: 22px 0 20px;
}

.odd-team-name {
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  margin-bottom: 0;
}

.odd-team-flex2 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.odd-team-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.bet {
  font-size: 16px;
  color: #FFFF00;
}

.match-odd-head {
  border-left: 3px solid #FFFF00;
  height: 46px;
}

.odd-back {
  margin-bottom: 0;
  color: #0DC5FF;
  font-weight: 400;
  font-size: 14px;
}

.odd-lay {
  margin-bottom: 0;
  color: #FFB5BD;
  font-weight: 400;
  font-size: 14px;
}

.odd-layer {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .col-md-2 {
    /* border: 1px solid red; */
    display: none;
  }


  .col-md-5 p {
    /* border: 1px solid red; */
    text-align: center;
  }

  .col-md-3 {
    text-align: center;
    /* border: 1px solid white; */
  }

  .footer-main-burger .footer-p {
    /* border: 1px solid red; */
    display: none;
  }
}

.Offersforyoudiv {
  /* background: #000000; */
  /* border: 1px solid red; */
  display: none;
}

.bg-blue-card {
  background: #a7d8fd;
}

.bg-blue-card2 {
  background: rgb(13, 202, 240);
  border-radius: 4px;
}

.bg-red-card {
  background: rgb(255, 181, 189);
  border-radius: 4px;
}

.bg-grey-card {
  background: #E8E8E8;
  border-radius: 4px;
  cursor: not-allowed;
}

/* terms-and-condition-page-css */
.terms-and-conditions {
  background-color: #1B1B1B;
  color: white;
  border: 1px solid black;
  padding-top: 50px;

}

.main-content-tac {
  width: 90%;
  margin: auto;
}

.terms-and-conditions p {
  margin-top: 20px;
}

.terms-and-conditions h2 {
  /* border: 1px solid blue; */
  color: yellow;

}

.terms-and-conditions p {
  color: white;
  line-height: 25px;
}

.contentn-bets-terms-and-condition {
  padding-top: 35px;
  /* border: 1px solid red; */
}

#mobile_sidebar {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9;
  top: 0;
  right: 0;
  background: black;
}

.ul-footer .tersm-li-tag {
  /* border: 1px solid red; */
  text-decoration: none;
  /* border-bottom: none; */
}

.cashback-banner {
  background-color: #000000;
  width: 100%;
}

.cashback-banner-image {
  width: 100%;
}

.cashback-content {
  background: #0D0A1B;
  padding: 84px 24px 24px 24px;
}

.cashback-content-head {
  background: linear-gradient(90deg, #FF8008 0%, #FFC837 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 12px;
}

.cashback-content-para {
  width: 312px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: justify;
  color: #FFFFFF;
  margin-bottom: 18px;
}

.cashback-nav {
  background: #111111;
  border-radius: 0px 0px 8.90625px 8.90625px;
}

.cashback-nav-items {
  background: linear-gradient(90deg, #FF8008 0%, #FFC837 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  font-size: 10.6875px;
  text-decoration-line: underline;
  margin-bottom: 0;
  padding: 12px 9px 11px 20px;
}

.nav-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-first {
  border-right: 2px solid #FF8008;
  text-decoration: underline;
  text-decoration-color: #FF8008;
  background: #FCFCFC;
  border-radius: 0px 0px 0px 8.90625px;
}

.nav-sec {
  background: #FCFCFC;
  border-radius: 0px 0px 8.90625px 0px;
  text-decoration: underline;
  text-decoration-color: #FF8008;
}

.nav-sec:hover {
  background: #FCFCFC;
  text-decoration: underline;
  text-decoration-color: #FF8008;
}

.nav-sec2 {
  border-right: 2px solid #FF8008;
}

.nav-sec2:hover {
  background: #FCFCFC;
  border-radius: 0px 0px 0px 8.90625px;
}

.nav-sec3:hover {
  background: #FCFCFC;
  border-radius: 0px 0px 8.90625px 0px;
}

.nav-list {
  border-right: 2px solid #FF8008;
}



/* Firefox */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=checkbox] {
  border-radius: 0 !important;
}

.fancy-bets-odds {
  font-size: 10px;
  bottom: -6px;
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.owl-dots {
  position: absolute;
  /* background: black; */
  margin-top: -30px !important;
  /* color: blue !important; */
  z-index: 100;
  /* text-align: center; */
  left: 45%;
  /* margin: auto; */
}


.owl-carousel span {
  width: 13px;
  height: 13px;
  margin: 5px 7px;
  background: grey !important;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;

}

.owl-carousel .active span {
  width: 13px;
  height: 13px;
  margin: 5px 7px;
  background: #fff !important;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  /* border: 1px solid red; */
}

.promotions-bars-title-head {
  height: 25px;
}

.promotions-bars-title-head marquee {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.03em;
  /* height: 15px; */
  color: #111111;
}

.border-footer-tags {
  /* border: 1px solid red; */
  text-decoration: none;
  color: white;
  background-color: #111111;
}

.images-dashboard-user-profile {
  text-decoration: none;
  color: white;
}

.payment-arrow {
  margin-left: 56px;
}

.desposit-mobile-card {
  border: none !important;
}

.desposit-mobile-card-head {
  font-size: 18px;
  font-weight: 600px;
  padding-top: 40px;
  padding-bottom: 15px;
}

.radio-upi {
  display: flex;
  align-items: center;
  padding: 0 22px;
}

.copy-icon {
  margin-left: -20px;
}

.qr-icon {
  margin-left: 27px;
}

.profile-box text-center card m-auto input::placeholder {
  color: red;
}

.slant-container {
  transform: skew(0deg);
}

@media (min-width:0px) and (max-width:992px) {
  /* .traslate-copm {
      transform: translateX(-7px);
  
    } */
}

.slant-container-0 {
  transform: skew(0deg);
  scale: 0.6;
}

.popularSelected {
  background: linear-gradient(90deg, #039e53 1.27%, #077227 100%);
}

.betsInputShadow {
  box-shadow: rgb(204, 219, 232) 2px 2px 4px 0px inset, rgba(255, 255, 255, 0.5) -2px -2px 2px 1px inset;
}

.inputs-banks-holders-div {
  display: flex;
  flex-direction: column;
  padding: 10px;



}

.inputs-banks-holders-div input {
  margin-bottom: 10px;
  width: 80%;
  margin-right: 20%;
}

.actions-buttons-div {
  /* width: 100%; */
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-right: 20%;

}

.inputs-banks-holders-div select {
  margin-bottom: 10px;
  width: 80%;
  margin-right: 20%;
}

.bank-btn {
  /* border: 1px solid red; */
  margin-top: 50px;
}

.inputs-banks-holders-div {
  width: 100%;
  /* border: 1px solid red; */
  margin-left: 0 !important;
  margin-top: 0 !important;

}

.bank-details {
  background: #FFFFFF;
  /* width: 50%; */
  border-radius: 4px;
  margin-top: 12px;
  padding: 8px;

}

.bank-details-div {
  background: #EBF6F0;
  padding: 0 10px 10px 10px;
  border-radius: 4px;
}

.bank-acc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bank-btn-submit {
  margin-bottom: 15px;
  margin-left: -10px;
  padding: 10px 16px;
  background: #0d99ff;
  border-radius: 4px;
  color: white;
  border: none;
  font-weight: 600;
  font-size: 9.42308px;
}




.bank-edit-div {
  display: flex;
  gap: 17%;
}

.bank-edit {
  color: #0D99FF;
  font-weight: 400;
  font-size: 8.07692px;
}

.bank-detail-entry {
  text-align: initial !important;
  /* border: 1px solid red; */
  color: #009E26;
}

.bansk-holder-two {
  width: 50%;
  margin: auto;
}

.remark {
  background: #FFFFFF;
  border-radius: 1.34615px;
  color: #009E26;

}

.add-bank-bg {
  background: #0D99FF;
  border-radius: 8px;
  width: 150px;
  padding: 20px;
  margin: auto;
  margin-bottom: 50px;
}

.bet-karo-marquee-heading {
  /* border: 1px solid red; */
  display: inline-block;
  padding: 0px 1em 0px 2em;
  height: 30px;
  line-height: 2.2;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.sidebar-between-section {
  /* border: 1px solid red; */
  border-radius: 0%;
}

.betslip_odds {
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
}

.betslip_icons {
  width: 20px;
  height: 20px;

}

.betslip_icons>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.border-red-4 {
  border-top: 4px solid rgb(250, 114, 144);
}

.border-blue-4 {
  border-top: 4px solid rgb(120, 187, 253);
}

.betslip_card_text_1 {
  display: flex;
  -webkit-box-align: center;
  align-items: flex-start;
  flex-direction: column;
  color: rgb(32, 57, 116);
  font-size: 12px;
}

.betslip_card_text_2 {
  color: rgb(53, 73, 94);
}

.w-20 {
  width: 20%;
}

.w-16 {
  width: 16%;
}

.w-10 {
  width: 10%;
}

.w-35 {
  width: 35%;
}

.betslip_odds_button {
  height: 30px;
  border: 1px solid black !important;
}

.betslip_odds_input:focus {
  outline: none;
  box-shadow: none;
}

.w-49 {
  width: 49%;
}

@media screen and (max-width:676px) {
  .overview-section-part {
    /* border: 1px solid red; */
    width: 90%;
    margin: auto;
    margin-top: 24px;
    background: linear-gradient(180deg, rgba(0, 158, 38, 0.6) 0%, rgba(1, 73, 18, 0.6) 118.68%);
    border: 1px solid rgba(243, 243, 243, 0.3);
    padding-bottom: 14px;
  }

  .normal-overview-empty {
    width: 90%;
    margin: auto;
    height: 10px;
    width: 10px;
    background-color: #009E26;

  }

  .overview-tasks-user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* border: 1px solid red; */
    width: 52%;
    /* margin-left: 1px; */

  }

  .overview-tasks-user-1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* border: 1px solid red; */
    width: 43%;
    /* margin-left: 1px; */

  }

  .overview-tasks-user-1 p {
    /* font-family: 'Sarabun'; */
    /* font-style: normal; */
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-top: 15px;
  }

  .overview-tasks-user p {
    /* font-family: 'Sarabun'; */
    /* font-style: normal; */
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-top: 15px;
  }

  .balance-overview-page-dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    margin: auto;
    gap: 10px;
    /* padding-bottom: 9px; */
    /* border: 1px solid red; */
    /*  */
  }

  .balance-overview-page-dashboard .balance-overview-one {
    background-color: #FFFFFF;
    padding: 20px;
    width: 50%;

  }

  .balance-overview-page-dashboard .balance-overview-two {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    width: 50%;
    /* border: 1px solid red; */
  }

  .text-overview-dashboard {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #111111;
    width: 50%;
    /* border: 1px solid red; */
    margin-top: 5px;
  }

  .balance-overview-one h4 {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #111111;
  }

  .balance-overview-two-under {
    background-color: #FFFFFF;


  }

  .balance-overview-two-under .inside-two-under {
    background-color: #FFFFFF;
    width: 90%;
    margin: auto;
    margin-top: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid red; */
  }

  .balance-overview-three-under .inside-two-under {
    background-color: #FFFFFF;
    width: 90%;
    margin: auto;
    margin-top: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .inside-two-under .items-price {

    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-transform: capitalize;

    color: #E64C5E;
  }

  .inside-two-under img {
    margin-top: -10px;

  }

  .inside-two-under .items-prices {

    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-transform: capitalize;

    color: #019D26;

  }

  .inside-two-under+div {
    /* border: 1px solid red; */
    width: 90%;
    margin: auto;
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    display: flex;
    align-items: center;
    text-transform: capitalize;

    color: #111111;
    padding-bottom: 9px;
  }

  /* profile section part */
  .Profile-section-part {
    /* border: 1px solid red; */
    width: 90%;
    margin: auto;
    margin-top: 24px;
    background: rgba(104, 107, 104, 0.7);
    border: 1px solid rgba(243, 243, 243, 0.3);
    padding-bottom: 14px;
  }

  .Profile-section-part .normal-overview-empty {
    height: 10px;
    width: 10px;
    background-color: #009E26;

  }
}

.square_green {
  height: 10px;
  width: 10px;
  background: linear-gradient(rgba(0, 158, 38, 0.6), rgba(1, 73, 18, 0.6));
}

.green_glass {
  background: rgba(12, 250, 15, 0.2) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(1px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  border: 1px solid rgba(12, 250, 15, 0.3) !important;
}

.mobileBg1 {
  background-image: url("./assets/images/top_navbar_1.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.promotionInternalBG {
  background-image: url("./assets/images/promotionInternal.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 90vh;
}

.accountInfoBg {
  background-image: url("./assets/accountInfoBg.svg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.changePasswordBG {
  background-image: url("./assets/images/changePasswordBG.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.bankDetailsBG {
  background-image: url("./assets/images/bankDetailsBG.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.depositeBG {
  background-image: url("./assets/images/depositeBG.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.withdrawBG {
  background-image: url("./assets/images/withdrawlBG.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.historyBG {
  background-image: url("./assets/images/historyBG.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.myBetsBG {
  background-image: url("./assets/images/myBetsBG.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.profitAndLossBG {
  background-image: url("./assets/images/profitAndLossBG.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.accountStatementBG {
  background-image: url("./assets/images/accountStatementBG.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.messegeBG {
  background-image: url("./assets/images/messegeBG.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.turnoverBG {
  background-image: url("./assets/images/turnoverBG.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.mobileBgPromo {
  background-image: url("./assets/images/promotionBack.svg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.homepageInternalBg {
  background-image: url("./assets/images/homepageInternal.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow-y: auto;
}

.sportsBackgroundCricket {
  background-image: url("./assets/images/homepageInternal.png");
}

.sportsBackgroundTennis {
  background-image: url("./assets/images/backgroundTennis.png");
}

.sportsBackgroundFootball {
  background-image: url("./assets/images/backgroundFootball.png");
}

/* accordian promotionpage css */

.mobilepromotionBg1 {
  background-image: url("./assets/images/promotionbackground.svg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 125vh;
  overflow-y: auto;
}

.welcome-bonus-promotion {
  background-image: url("./assets/images/welcomebonusPromo.svg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.border-white-promotional {
  border: 1px solid white;
  width: 90%;
  margin: auto;
  margin-top: 20px;
  max-height: calc(100vh - 400px);
  overflow: auto;
  padding: 10px;
  /* padding-bottom: 10px; */
}

.border-white-promotional::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.border-white-promotional {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.lorem-promotionap-text {
  /* font-family: 'Sarabun'; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  background: #FF8008;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}

.lorem-promotional-p-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;

}

.text-carousel-homapegeCategory {
  /* font-family: 'Sarabun'; */
  font-style: normal;
  font-weight: 700;
  font-size: 10.6875px;
  line-height: 14px;
  background-image: linear-gradient(to bottom right, #8e9eab, #eef2f3);
}

.text-carousel-homapegeCategory.active {
  background-image: linear-gradient(to right, #24C6DC, #514A9D);
  border-radius: 0px;
  color: #ffffff !important;
}

/* .details-promotion-pages {
  text-align: center;
  background-color: #FFA500;
  width: 87%;
  margin-left: 15px;

  /* padding-right: 10px; */

/* } */

.my-account-color {
  /* font-family: 'Sarabun'; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: #FFA500;
}

.accordion-button {
  /* font-family: 'Sarabun'; */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
  color: #FFA500;
  /* border: 1px solid red; */

}


.accordion-item {
  background-color: rgba(104, 107, 104, 0.7);
}

.accordion-body {

  background-color: rgba(104, 107, 104, 0.7);
  border: 1px solid rgba(243, 243, 243, 0.3);
  color: #FFFFFF;
}

.accordion-button:not(.collapsed) {
  color: #FFA500 !important;
}



.profilAndLossAccordian::after {
  position: absolute;
  right: 20px;
  top: 13px;
  background-image: url("./assets/circleDownArrow.svg") !important;
}

.faq-bar-dropdown {
  /* border: 1px solid red; */
  /* margin-left: 8px; */
  padding: 10px;

}

.border-footer-tags-FAQ {
  text-decoration: none;
  color: white;
}

.welcome-bonus-promotion {
  /* font-family: 'American Captain'; */
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 41px;
  /* identical to box height, or 100% */
  box-sizing: border-box;

  color: #FFFFFF;


}


.accordionButton:not(.collapsed)::after {
  background-image: url("./assets/images/UPARROW.svg");
  transform: var(--bs-accordion-btn-icon-transform);
  /* border: 1px solid white; */

}

.welcome-bonus-promotion-page-css {

  width: 100%;
  margin: auto;
  /* margin-bottom: 8px; */
  margin-top: 14px;



}

.welcome-bonus-promotion-page-css p {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  background-color: #FFA500;
  padding: 7px;
  color: #111111;
}

.inside-promotion-bonus {
  padding: 20px;
}

.inside-accordian-flex {
  background-color: #009E26;
  padding: 10px;
}

.links-promotional-page {
  text-decoration: none;
  color: white;
  cursor: pointer;
}






.accordionButton::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: url("./assets/images/downArrow.svg") !important;
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  color: white;
  /* border: 1px solid red; */
}



.accordion-header .accordion-button {

  background-color: rgba(0, 158, 38, 0.6);
  /* border: 1px solid red */
  border: 1px solid rgba(243, 243, 243, 0.3);

}

.accordion- .mobileBg2 {
  background-image: url("./assets/images/top_navbar_2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.black_glass {
  background: rgba(0, 0, 0, 0.7) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(1px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
}

.white_glass {
  background: rgba(104, 107, 104, 0.7) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(1px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  border: 1px solid rgba(243, 243, 243, 0.3) !important;
}

.white_glass2 {
  background: rgba(255, 255, 255, 0.7) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(1px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  border: 1px solid rgba(243, 243, 243, 0.3) !important;
}

/* new page bank -details */
.background-bank-details {
  background-color: rgba(0, 0, 255, 0.594);
}

.bank-details-new-page {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 89%;
  margin: auto;
  padding-top: 25px;
}

.bank-details-new-page .new-bank-details {
  /* font-family: 'Sarabun'; */
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #FFFFFF;
  width: 150%;
}

.new-bank-details-1 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #FFFFFF;
  width: 200%;
}

.new-bank-details-1 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #FFFFFF;
  width: 200%;
}

.main-container-banks-details {
  width: 90%;
  margin: auto;
  background-color: rgba(0, 158, 38, 0.6);
  border: 1px solid rgba(243, 243, 243, 0.3);
}

.main-container-banks-details select {
  width: 100%;
  height: 45px;
  padding: 10px;
  margin-top: -12px;
}

.midcontainer-bank-page {
  padding: 10px;
}

.midcontainer-bank-page .inputs-bank-details {
  width: 100%;
  height: 45px;
  background-color: #FFFFFF;
  margin-top: 8px;
  border: none;
  padding: 10px;
}

.inputs-bank-details-1 {
  width: 100%;
  height: 45px;
  background-color: #FFFFFF;
  margin-top: 8px;
  border: none;
  padding: 10px;
  margin-top: -15px;
}


.actions-submit-reet {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 93%;
  margin: auto;
  padding-top: 20px;
  gap: 10px;
  margin-bottom: 24px;
}

.actions-submit-reet div {
  /* display: ; */
  padding: 20px;
  background-color: red;
  padding: 13px 16px;
}

.actions-submit-reet .button1 {
  background-color: #FFA500;
  /* font-family: 'Sarabun'; */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  /* identical to box height, or 114% */


  text-transform: capitalize;

  color: #0B0B13;

}

.actions-submit-reet .button2 {
  background-color: #FFFFFF;
  /* font-family: 'Sarabun'; */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  /* identical to box height, or 114% */


  text-transform: capitalize;

  color: #0B0B13;

}

/* footer icons */
.footer_icons {
  width: 20px;
  height: 20px;

}

.footer_icons img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn_commn_yellow_1 {
  background-color: #FFA500 !important;
  height: 42px !important;
  /* color:#0B0B13; */
}

/* current bets */
.currentbets_title {
  font-size: 20px;
  color: #FFFFFF;
  font-weight: 700;
}

/* btn yellow */
.btn_commn_yellow {
  background-color: #FFA500;
  color: #0B0B13;
  border-radius: 0px;
  width: 114px;
  padding: 18px 21.5px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.btn_commn_white {
  background-color: #FFFFFF;
  color: #0B0B13;
  border-radius: 0px;
  width: 114px;
  padding: 18px 21.5px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.start_date_Wrapper {
  background-color: green;
  width: 100%;
  color: #ffff;
  display: flex;
  justify-content: space-between;
  padding-right: 0;
}

.date_input {}

.cleander_dropedown {
  background-color: gray;
  width: 2rem;
  height: 2rem;
  padding: 5px 7px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .down_arrowicon{
  width: 15px;
  height: 9px;
  display: flex;
}
.down_arrowicon img{
  width:100%;
  height: 100%;
  object-fit: cover;
} */
.green_btm_border {
  border-bottom: 0.5rem solid rgba(0, 158, 38, 0.7);
}

.badge-blue {
  width: fit-content;
  border-radius: 0px;
  padding: 0.5rem 1rem;
  background-color: #0D99FF;
  color: #fff;
  position: absolute;
  right: 0px;
  top: 1rem;
}

.badge-red {
  width: fit-content;
  border-radius: 0px;
  padding: 0.5rem 1rem;
  background-color: #E64C5E;
  color: #fff;
  position: absolute;
  right: 0px;
  top: 1rem;
}

.dropdown_cstm_btn {
  background-color: #fff;
  border-radius: 0;
  color: #909090;
}

.dropdown_cstm_btn:hover,
.dropdown_cstm_btn:active,
.dropdown_cstm_btn:focus {
  background-color: #fff !important;
  border-radius: 0;
  color: #909090;
  box-shadow: none !important;
  border: none;
}

.dropdown_cstm_menu {
  position: absolute;
  will-change: transform;
  top: 0px;
  border-radius: 0px;
  left: 0px;
  transform: translate3d(0px, 37px, 0px)
}

.bank_dropdown .dropdown-toggle::after {
  display: none;
}

.attachment_image_upload>input {
  display: none;
}

.attachment_image_upload img {
  width: 20px;
  cursor: pointer;
}

.attachmnet_wrappper {
  background-color: #ffff;
  border: 2px dashed #454545;
}

.attachmnet_wrappper_red {
  background-color: #ffff;
  border: 2px dashed red;
}

.black_gradient {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.profitLossBg {
  background: #363636;
}

.profitLossBorder {
  border-top: 1px dashed white;
}

.profitLossCardBlue {
  background: #D5EDE7;
}

.textAutoAdjust {
  flex: 1;
}

.background-promotion-page {
  /* border: 1px solid red; */
  background-color: #FFA500 !important;
}


.crossbutton-cancel {
  border: none;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  /* border: 1px solid blue; */
}

.crossbutton-cancel img {
  width: 100%;
}

.btn_mobile_betslipCard:hover,
.btn_mobile_betslipCard:active {
  background: white;
  color: black;
}

/* .MuiSwitch-track {
  background-color: rgba(255, 255, 255, 0.25) !important;
} */

.tooltip-box-divs {
  /* border: 1px solid red; */
  font-family: 'Sarabun';
  width: 25%;
  margin: auto;
  /* width: 55px; */
  height: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  color: #000000;
  padding: 15px;
  margin-top: 10px;
  border: 1px solid black;
  background-color: #FFFFFF;
  /* position: absolute; */
}

.MuiSwitch-track {
  border: 1px solid grey;
}

.mobile_footer_new {
  bottom: -1px;
  width: 101%;
  /* height: 50px; */

}

.footer_center_menu {
  top: -50px;
  left: 50%;
  transition: all ease-in-out 0.2s;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.footer_mobile_overlay {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  bottom: 0;
  transition: all ease-in-out 0.05s;
}

.menu0 {
  width: 0px;
  top: 0;
}

.menu00 {
  width: 80vw;
  top: -120%;
}

.menu1 {
  top: -100px;
  left: -100px;
}

.menu2 {
  top: -150px;
  left: -60px;
}

.menu3 {
  top: -165px;
}

.menu4 {
  top: -150px;
  left: 60px;
}

.menu5 {
  top: -100px;
  left: 100px;
}

.green_round_border {
  border: 1px solid green;
  border-radius: 50%;
}

.centerFooterIcon {
  top: 200px;
  left: 0;
}

.zIndex_999 {
  z-index: 999;
}

.whatsappImage {
  width: 75px;
  height: 75px;
  right: 0;
  bottom: 75px;
}

.x-style {
  /* width: 50%;
  height: 75px;
  right: -208px;/
  bottom: 128px;
  z-index: 9999; */
  bottom: 160px;
  right: 17px;
  font-size: 12px !important
}

.whatsappImage-box {
  width: 67%;
  /* height: 8%; */
  right: 10px;
  bottom: 116px;
  overflow: hidden;
}

.main-text {
  font-size: 11px !important;
  padding: 0px !important;
  margin: 0px !important;
}

.inner-text {
  font-size: 10px !important
}

/* pagination */
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6rem;
  background: none;
}

.page-item {
  outline: none;
  border: none;
}

.pagination>li {
  display: inline-block;
  list-style: none;
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  color: #424242;
  outline: none;
  border-radius: 5px;
  border: none;
}

.pagination>li.active>a {
  color: #424242;
  background-color: #12352F;
  border-color: #12352F;
}

.page-item.active .page-link {
  background: rgb(14, 138, 16) !important;
  z-index: 0;
}

.mobile_menu_overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100px;
  width: 200px;
  border-radius: 150px 150px 0 0;
  background-color: green;
}

.footer_menu_circle>div {
  text-decoration: none;
}

.blue_gradient_background {
  background: linear-gradient(to right, #085078, #85D8CE);
}


.black_gradient_background {
  background: linear-gradient(to right, #232526, #414345);
}

.buttonGradientBackground {
  background-image: url("./assets/buttonGradient.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* min-height: 100vh; */
}



::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(107, 107, 107);
  border-radius: 20px;
}


#demo1 {
  background: #0e0e0e;
  padding: 0.5rem 0;
}

.accordionButton:focus {
  outline: none;
  box-shadow: none;
}

.down-arrow-radius {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;

}

.refrences-card-history-1 {
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
}

.refrences-card-history-2 {
  border-bottom-right-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.refrences-card-history-3 {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.refrences-card-history-4 {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.accordian-border {
  overflow: hidden;
  border-radius: 6px !important;
  border: none;
}

.welcome-prom-con {
  border-top-right-radius: 15px !important;
  background: beige;
  margin-left: 15px;
  margin-right: 15px;
  overflow: hidden;
  border-top-left-radius: 15px !important;
}

.accordion-bottom-radius {
  overflow: hidden;
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.online-casino-search {
  box-shadow: none !important;
  outline: none;
  border: none;
}

.casino-provider-tabs {
  padding: 0.9rem 2rem;
}


.casino-top-section {
  background-image: linear-gradient(to top right, #e7e7e7, #ffffff);
}

.casino-tabs-active {
  background-image: linear-gradient(to right, #5C0180, #480B53);
}

.casino-tabs {
  transform: skewX(8deg);
}

.casino-tabs>* {
  transform: skew(-8deg);
}

.iFrameParent {
  background: url("./assets/shimmer_gif.gif") center center no-repeat;
  margin: 0;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat; 
  background-attachment: fixed;
  width: 100vw;
}

/* .iFrameParent {
  background: url("./assets/gameLoading2.webp") center center no-repeat;
} */

.onlineGamesCard {
  cursor: pointer;
}

/* .liveScore_container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.liveScore_responsive_iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
} */


.background-box-gaming {
  background-color: rgba(196, 196, 196, 0.08);
  ;
}

.games-page-all-games {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  padding: 5px 10px;
  color: black;


}

.games-title-names {
  font-size: 15px;
  font-weight: 600;
  padding: 5px;
}

.container-all-games {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  /* width: 80%; */

}

.input-all-games-search {
  width: 50%;
  margin: auto;

}

.input-all-games-search input {
  border: 0.5px solid #363636;
  padding: 3px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  padding-left: 10px;
  width: 92%;
  height: 30px;
  font-size: 10px;
}


.width-input-box-algames{
  /* border: 1px solid red !important; */
  width: 109% !important;
}

.hideScrollbar::-webkit-scrollbar {
  display: none !important;
}

.suspended {
  opacity: 0.8;
  cursor:not-allowed;
}