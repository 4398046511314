.center-block {
    background-color: #000000;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 60px;
}

.head-block {
    padding-top: 50px;
    padding-bottom: 50px;
}

.heding-text {
    width: 166px;
    height: 27px;
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
}

.sub-text {
    width: 72px;
    height: 19px;

    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.message-block {
    width: 100%;
    height: 36px;
    background: #111111;
    border-radius: 8px;
}

.message-text {
    color: #FFFFFF
}

.main-image {
    background-image: url('../../assets/images/audience.png');
    width: 100%;
    height: 100%;
}

.three-block {
    width: 100%;
    background: #000000;
    margin-top: 20px;
}

.main-card {
    display: flex;
}

.card-one {
    background-color: #111111;
    height: 900px;

}

.card-two {
    background-color: #111111;
    height: 900px;
}

.card-three {
    background-color: #111111;
    height: 900px;
}

.navtabe {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    color: white;
}

.navul {
    list-style: none;
    display: flex;
}

#navli {
    padding: 0 20px;
    cursor: pointer;
    position: relative;
}

#navli.active::before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #0D99FF;
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
}

#navli:hover::before {
    transform: scaleX(1);
}

.underline {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #0D99FF;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
}

.live-match-first-line {
    color: #FFFFFF
}

.live-match-second-line {
    color: #999999;
}

.vectore-class {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0px 8px 20px;
    gap: 20px;
}

.forward-arrow {
    width: 37px;
    height: 37px;
    background: #424242;
    border-radius: 50%;
}

.bd-callout {
    border: 1px solid #eee;
    border-left-width: .25rem;
    border-radius: .25rem
}

.bd-callout-primary {
    border-left-color: #007bff
}

.card-hover-color {
    background-color: #111111;
}

.card-hover-color:hover {
    background-color: #D3D3D3;
    opacity: 5 s;
}

.slick-prev:before,
.slick-next:before {
    font-family: "Sarabun";
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: white !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}