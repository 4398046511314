.dashboard-account1 {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    padding: 30px;
    /* margin-bottom: 20px; */
}

.symbols-dashboard-back {
    display: flex;
    align-items: center;
    /* margin-bottom: 30px; */
}

.back-arrow {
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-right: 10px;
}

.dashboard-p {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
}

.inputs-banks-holders-div {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.form-group {
    width: 100%;
    /* margin-bottom: 30px; */
    position: relative;
}

.form-control {
    background-color: #f7f8f9;
    border: none;
    border-radius: 5px;
    color: #333;
    font-size: 16px;
    font-weight: 400;
    height: 50px;
    padding: 15px;
    width: 100%;
    transition: all 0.3s;
}

.form-control:focus {
    box-shadow: none;
    outline: none;
}

.form-group label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    color: black;
    text-align: left;
}

.invalid-feedback {
    color: #dc3545;
    font-size: 14px;
    margin-top: 5px;
    position: absolute;
    bottom: -20px;
margin-bottom: 5px;
    text-align: left;
}

.actions-buttons-div {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    width: 100%;
}

.btn-primary {
    background-color: #1a73e8;
    border-color: #1a73e8;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    height: 50px;
    padding: 0 25px;
    transition: all 0.3s;
}

.btn-primary:hover {
    background-color: #1652c5;
    border-color: #1652c5;
}

.btn-primary:active {
    background-color: #0d1a2e;
    border-color: #0d1a2e;
}

.btn-danger {
   
    border-radius: 5px;
    
    font-size: 16px;
    font-weight: 500;
    height: 50px;
    padding: 0 25px;
    transition: all 0.3s;
}

.btn-secondary:hover {
    background-color: #f0f0f0;
    border-color: #e0e0e0;
}

.btn-secondary:active {
    background-color: #ccc;
    border-color: #ccc;
}
  .pass-mobile-bg {
      background-color: #f7f8f9;
      padding: 15px;
      border-radius: 5px;
  }
 
  .symbols-dashboard-back .back-arrow {
    margin-left: unset !important;
  }
@media (min-width:0) and (max-width:992){
    .dashboard-account1 {
            width: 100%;
            margin: 0 auto;
            padding-top: 20px;
        }
    
        .symbols-dashboard-back {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    
        .back-arrow {
            height: 15px;
            cursor: pointer;
        }
    
        .dashboard-p {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
            text-align: center;
        }
    
        .actions-buttons-submit {
            margin-top: 20px;
        }
    
        .form-group {
            margin-bottom: 15px;
        }
     .form-group {
            margin-bottom: 15px;
        }
        label {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 5px;
            color: black;
        }
    
        input {
            border: none;
            border-radius: 5px;
            padding: 10px;
            font-size: 16px;
            font-weight: 500;
            width: 100%;
        }
    
        .invalid-feedback {
            color: #dc3545;
            font-size: 12px;
            margin-top: 5px;
            margin-bottom: 5px;
            text-align: left;
        }
    
        .actions-buttons-div {
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }
    
        .btn-primary,
        .btn-secondary {
            padding: 10px 20px;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 600;
        }
    
        .btn-primary {
            background-color: #0d6efd;
            border-color: #0d6efd;
            color: #fff;
        }
    
        .btn-primary:hover {
            background-color: #0b5ed7;
            border-color: #0b5ed7;
        }
    
        .btn-secondary {
            background-color: #fff;
            border-color: #6c757d;
            color: #6c757d;
            margin-left: 10px;
        }
    
        .btn-secondary:hover {
            background-color: #f2f2f2;
        }
    
        .pass-mobile-bg {
            background-color: #f7f8f9;
            padding: 15px;
            border-radius: 5px;
        }
}