.share-card{
    width: 10%;
    right: 15%;
    bottom: 118px;
    overflow: hidden;
}
.share-card-close{
    /* width: 10%; */
    /* right: 20%; */
    /* bottom: 130px; */
    overflow: hidden;
}