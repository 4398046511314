ul {
  list-style: none;
}

ul li::before {
  content: "\2022";
  color: red;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.upi-pay-selected {
  background-color: rgb(183, 246, 253) !important;
  cursor: pointer;
  color: black;
}
.upi-pay-selecteded {
  background-color: rgb(183, 246, 253) !important;
  cursor: pointer;
  color: black;
}