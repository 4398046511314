.font-8 {
    font-size: 14px;
}

.betSlipCardDiv {
    /* border-radius: 10px !important; */
    /* border: 1px solid black; */
}

.betSlipCardDiv1 {
    border-top-left-radius : 5px !important;
    border-top-right-radius : 5px !important;
}
