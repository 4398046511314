.all-games-tabs-container::-webkit-scrollbar {
    display: none;
}

.all-games-tabs2 {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}