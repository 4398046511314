.card-payment-scaner {
    /* display: flex; */
    justify-content: space-between;
    padding: 40px 20px 40px 20px;
    background-color: #ffffff;
    width: 95%;
    margin: auto;
    /* border: 1px solid red; */
    color: #424242;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.profile-box {
    width: 200px;
    height: 200px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
}

.opacity0 {
    opacity: 0 !important;
}