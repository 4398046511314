/* colors */
/* green */
.text-green_01 {
    color: #00FF59;
}

.text-green_02 {
    color: #009E26;
}

/* yellow */
.text-yellow_01 {
    color: #FFA500;
}

/* red */
.text-red_01 {
    color: #FF4359;
}

.text-red_02 {
    color: #E64C5E;
}

/* gray black */
.gray_black_01 {
    color: #111111;
}

/* black */
.black_01 {
    color: #111111;
}

/* font-weight */
.fw_100 {
    font-weight: 100;
}

.fw_200 {
    font-weight: 200;
}

.fw_300 {
    font-weight: 300;
}

.fw_400 {
    font-weight: 400;
}

.fw_500 {
    font-weight: 500;
}

.fw_600 {
    font-weight: 600;
}

.fw_700 {
    font-weight: 700;
}

.fw_800 {
    font-weight: 800;
}

.fw_900 {
    font-weight: 900;
}

.fw_bold {
    font-weight: bold;
}

.fw_bolder {
    font-weight: bolder;
}

/* line_heights */
.lh_0 {
    line-height: 0;
}

.lh_12 {
    line-height: 12px;
}

.lh_14 {
    line-height: 14px;
}

.lh_15 {
    line-height: 15px;
}

.lh_18 {
    line-height: 18px;
}
.lh_33 {
    line-height: 33px;
}

/* font-sizes */
.fs_8 {
    font-size: 8px;
}

.fs_9 {
    font-size: 9px;
}

.fs_10 {
    font-size: 10px;
}

.fs_11 {
    font-size: 10px;
}

.fs_12 {
    font-size: 12px;
}

.fs_13 {
    font-size: 13px;
}

.fs_14 {
    font-size: 14px;
}
.fs_16 {
    font-size: 16px;
}
.fs_40 {
    font-size: 40px;
}

/* margins */
.me_0-15 {
    margin-right: 0.15rem;
}
/* margins */
.me_0-25 {
    margin-right: 0.25rem;
}

.mb_4rem {
    margin-bottom: 4rem !important;
}
.mb_5rem {
    margin-bottom: 5rem !important;
}

.pb_4rem {
    padding-bottom: 4rem !important;
}

/* height */
.h-32 {
    height: 32px;
}

.h-42p {
    height: 42px;
}

.h-45 {
    height: 45%;
}

.h-33 {
   height: 33%
}

.h-36 {
    height: 36%
}
.min-h-100vh {
    min-height: 100vh
}

.min-h-70vh {
    min-height: 70vh
}

/* width */
.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;
}

/* border */
.border-succes-5 {
    border-bottom: 5px solid #009E26 !important;
}

.bordeer-right-2-w {
    border-right: 2px solid white !important;
}

/* position */
.position_relative {
    position: relative;
}

/* cursor */
.cursor-pointer {
    cursor: pointer;
}

/* text */
.td-none {
    text-decoration: none !important ;
}


/* opacity */
.opacity-0 {
    opacity: 0 !important;
}

.opacity-1 {
    opacity: 0.2 !important;
}

.opacity-2 {
    opacity: 0.4 !important;
}

.opacity-3 {
    opacity: 0.6 !important;
}

.opacity-4 {
    opacity: .8 !important;
}

.opacity-5 {
    opacity: 1 !important;
}

/* width */
.w-18 {
    width: 18px !important;
}

/* alignment */
.left-0 {
    left: 0;
}

.right-0 {
    right: 0;
}

.right-10 {
    right: 10px;
}

/* zIndex */
.zIndex_9 {
    z-index: 9;
}

/* object fit */
.of_cover {
    object-fit: cover;
}

/* background */
.bg-green_01 {
    background: #009E26;
}

.bg-black {
    background: #000000;
}

/* border */

.border-light_01 {
    border: 1px solid #535353;
}

.border-yellow_01 {
    border: 1px solid yellow;
}

.border-yellow_02 {
    border: 2px solid yellow;
}

.border-white_01 {
    border: 1px solid white;
}


/* rounded */

.rounded-2 {
    border-radius: 2px !important;
}
.rounded-4 {
    border-radius: 4px !important;
}
.rounded-6 {
    border-radius: 6px !important;
}
.rounded-8 {
    border-radius: 8px !important;
}
.rounded-10 {
    border-radius: 10px !important;
}
.rounded-12 {
    border-radius: 12px !important;
}
.rounded-14 {
    border-radius: 14px !important;
}
.rounded-16 {
    border-radius: 16px !important;
}
.rounded-left-4 {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

/* padding */
.px-2rem {
    padding-left: 2rem;
    padding-right: 2rem;
}